import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import pic00 from '../assets/images/ChcsdLogoColor.png'
import pic01 from '../assets/images/TeachSDLogoColor.png'

const About = (props) => (
    <Layout>
        <Helmet>
            <title>About CHCSD</title>
            <meta name="description" content="About Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>About</h1>
                    </header>
                    <div className="table-wrapper">
                        <table className="alt">
                            <tbody>
                                <tr>
                                    <td>
                                        <section id="CHCSD">
                                            <span className="image left"><img src={pic00} alt="" /></span>
                                            <p>While there is no denying that considerable volunteer effort is offered to present the Christian Homeschool Conference of South Dakota (CHCSD), it is humbling and amazing to see the magnification and direction of our gracious Father through His Son in the pieces falling together and the work being completed. We, the CHCSD Committee, firmly believe that this blessing is granted in order to encourage, inspire and invigorate the Christian homeschooling community. We pray that this will be the case for your family with this year's conference.</p>
                                            <p>Conference Committee volunteer families: Brunick, Brunick-Spieker, Olmstead, Rusanowski.</p>
                                            <p>Special thanks are extended to the CHCSD Committee and TEACHSD Board. The time and work they contribute to both the conference and management of TEACHSD is appreciated.</p>
                                            <p>We also want to thank the Sioux Falls Convention Center for the use of their wonderful facility.</p>
                                        </section>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <section id="TEACHSD">
                                            <span className="image right"><img src={pic01} alt="" /></span>
                                            <p><a href="http://teachsd.net">TEACHSD</a> is a non-profit 501(c)3 corporation dedicated to the cultivation, protection and promotion of Christian homeschooling and the homeschool community in the state of South Dakota. It pursues these goals through the coordinated efforts of its committees. </p>
                                        </section>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    </Layout>
)

export default About